<template>
  <div class="container">
    <div class="row justify-content-between">
      <!-- Date Section -->
      <div class="col-6 col-md-6 col-sm-6 col-xs-6" style="text-align: left">
        <p class="selected_date">
          {{ new Date(date_selected).toDateString() }}
        </p>
      </div>
      <!-- Price Section -->
      <div class="col-6 col-md-6 col-sm-6 col-xs-6 text-md-right" style="text-align: right">
        <strong class="total_amount">USD{{ availability_list[0]?.formated_price }}</strong>
      </div>
    </div>

    <!-- Events Section -->
    <div class="events_wrapper">
      <div class="events_container" v-if="availability_list.length > 0">
        <AvailableEvent
          v-for="(event, index) in availability_list"
          :key="index"
          :availability="event"
          class="event-item"
        />
      </div>
      <div class="not_items_container" v-else>
        <h3>No spots available</h3>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableEvent from './AvailableEvent.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "AvailableEventList",
  components: {
    AvailableEvent,
  },
  computed: mapGetters(['availability_list', 'date_selected']),
  methods: {
    cancelBooking() {
      // Your cancel action logic here
    },
  },
};
</script>
<style scoped>
.main_container {
  text-align: left;
}

.selected_date {
  color: green;
  font-size: 1.5rem;
  font-weight: 500;
}

.total_amount {
  font-size: 1.5rem;
}

.events_container {
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
  max-height: 200px;
}

.event-item {
  margin-bottom: 1rem;
}

.cancel-button {
  width: 100%; /* Full-width on smaller screens */
}

@media (max-width: 1400px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .selected_date, .total_amount {
    font-size: 1.2rem;
  }
}
@media (max-width: 1200px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .selected_date, .total_amount {
    font-size: 1rem;
  }
}

</style>
