<template>
  <div>
      <hr>
    <p>
        confirmation email sent to {{email_to}}
    </p>  
    <a @click="fullCleanUp" class="btn btn-success">
        Ok
    </a>

  </div>
</template>

<script>
import {mapActions} from 'vuex';
export default {
    name:'PaymentConfirmationFooter',
    props:{
        email_to:{
            type: String
        },
        redirect_url: {
            type: String
        }
    },
    methods:{
        ...mapActions(['resetStateToDefault']),
        fullCleanUp(){
            this.$store.dispatch('resetStateToDefault');
            window.location = this.redirect_url;
        }
    }
}
</script>

<style>

</style>