import Vuex from 'vuex';
import mod from './modules/mod';
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
    modules:{
        mod,
    },
    plugins: [createPersistedState()],

});