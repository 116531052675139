<template>
<div class='input_container form-group'>
    <p class="label_formater"> {{label_title}} </p>
    <vSelect :options="available_languages"
    v-model="LanguageSelected" class='select_renderer'
    :clearable='false'/>
</div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import vSelect from 'vue-select';
export default {
  name: 'LanguageSelect',
  components:{
    vSelect
  },
  props: {
    label_title:{
      type: String
    }
  },
  computed:{
    ...mapGetters(['available_languages', 'language_preferred']),
    LanguageSelected:{
      get(){
        return (this.language_preferred)? this.language_preferred: {"code":"en_US","label":"English"};
      },
      set(value){
        this.$store.dispatch('selectDesiredLanguage', value);
      }
    }
  },
  methods:{
    ...mapActions(['selectDesiredLanguage']),
  },
}
</script>

<style scoped>
.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.select_renderer{
    cursor: pointer;
}


.error_displayer{
    max-height: 20%;
}
.error_message{
    margin: 0px 10px 1px 10px;
	padding: 0px 0px 1px 0px;
    color: red;
}
.error_displayer{
	height: 39px;
	text-align: left;
	margin: 10px 10px 10px -26px;
	padding: 10px 10px 10px 0px;
}
.input_container{
    margin: 0px 0px 25px 10px;
}

.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.error-boarder {
  border-color: red;
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #f7f7f7;
	background-clip: none;
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
    border-bottom: none;
	border-radius: 0px;
    background-color: #f7f7f7;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom_input {
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0px;
    background-color: #f7f7f7;
    height: 26px;
	/* border: solid black; */
}

</style>
