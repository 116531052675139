<template>
  <div>
      <h3>
          {{ header_title }}
      </h3>
      <hr>
  </div>
</template>

<script>
export default {
    name:'PaymentConfirmationHeader',
    props:{
        header_title:{
            type:String,
            default: "Payment Confirmation"
        }
    },
}
</script>

<style>

</style>