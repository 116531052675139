// this is just the header based on the existing data

<template>
  <div class="title_container">
    <h2 class="form_title">
      <b>
        Your payment Includes
      </b>
    </h2>
    <h3 class="event_name_title">
      {{event_name}}
    </h3>
    <h5> {{ selected_date }}</h5>
    <div class="container mt-4">
      <div class="row">
        <div class="col col-xs-2">
          <h6><strong>Reservation</strong></h6>
        </div>
        <div class="col col-xs-2">
          <h6>{{ people_number }} Pax</h6>
        </div>
        <div class="col col-xs-2">
          <h6>USD{{ amount }}</h6>
        </div>
      </div>
      <div class="row" v-if="property_fetcher.configuration.tax_invoice">
        <div class="col">
          <h6><strong>ITBIS</strong></h6>
        </div>
        <div class="col">
          <h6>{{ property_fetcher.configuration.tax_invoice.tax }}%</h6>
        </div>
        <div class="col">
          <h6>USD${{ tax_invoice_amount.taxAmount }}</h6>
        </div>
      </div>
      <div class="row" v-if="property_fetcher.configuration.tax_invoice">
        <div class="col">
          <h6><strong>Service Charge</strong></h6>
        </div>
        <div class="col">
          <h6>{{ property_fetcher.configuration.tax_invoice.service_charge }}%</h6>
        </div>
        <div class="col">
          <h6>USD${{ tax_invoice_amount.serviceChargeAmount }}</h6>
        </div>
      </div>
      <hr/>
      <div class="row" v-if="property_fetcher.configuration.tax_invoice">
        <div class="col">
          <h6>Total Amount</h6>
        </div>
        <div class="col">
        </div>
        <div class="col">
          <h6> <b>USD${{ tax_invoice_amount.totalAmount }}</b></h6>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BookingFormHeader",
  props:{
    event_name:{
      type: String
    },
    selected_date:{
      type: String
    },
    people_number:{
      type: Number
    },
    amount:{
      type: String
    }
  },
  computed: {
    ...mapGetters(['property_fetcher', 'tax_invoice_amount']),
  },

}
</script>

<style scoped>
.title_container{
  text-align: left;
  padding: 10px 5px 5px 10px;
  max-width: 52%;
}
.event_name_title{
  color:green;
}
.total_liner{
  text-align: right;
}
/* .title_container{
    max-width: 100%;
} */
@media screen and (max-width:900px) {
  .title_container{
    max-width: 100%;
  }
}
</style>
