<template>
<h2 class="form_title">
        Booking Cancellation Request
    </h2>
  <div class="main_container_form container generic_floating_container" id="cancel_container">
    <br>
    <div class="user_container" id='cancellation_form'>
        <h3 class="form_title">
            <b>
              Please confirm the following information
            </b>
        </h3>
        <CancelBookingForm />
          <ModalDialog form_title="Cancel Booking"
         action_btn_render_class="btn btn-danger"
         action_btn_title="Cancel Booking"
         :message="'Are you sure you want to cancel your booking ?'"
         action_name="cancelBooking"
         @performEmit="performEmit"/>
    </div>
  </div>
</template>

<script>
import CancelBookingForm from '../components/CancelBookingForm.vue'
import ModalDialog from '../components/modalDialog.vue'
import {mapActions, mapGetters} from 'vuex';
export default {
  name:"cancelBookingView",
  props: {},
  components: {CancelBookingForm, ModalDialog},
  computed: {
    ...mapGetters(['']),
  },
  methods: {
    ...mapActions(['setRefundErrors','ValidateBookingCancellation']),
     performEmit(data){
            /*
                it performs the action based on existing name
                this takes an object with the name of the task
                and the item to process in case there's one.

                need to make this a promise so that it works
                asynchronously.
            */
            let payload =  {cancel_hash:this.$route.params.cancel_token,
                              user_token: this.$route.params.prop_id}
            this.$store.dispatch('sendBookingRefund',payload).then(
            (response)=>{
              this.$store.dispatch('cleanRefundFields');
              if (response.status){
                  document.getElementById('close_modal_btn').click();
                  this.$toast.add({severity:'success',
                                  summary:'Booking Refund Successful',
                                  detail: "Your refund request has been successfully created",
                                  life:7000})
                  this.$store.dispatch('setSuccessRefundMessage', response).then(()=>{
                      this.$router.push({name:'success_refund',
                                         params:{prop_id:payload.user_token,
                                                 cancel_hash: payload.cancel_hash}});
                  })

                    }
              else{
                this.$toast.add({severity:'error',
                                  summary:'Booking Refund Error',
                                  detail: response.error,
                                  life:7000})
                document.getElementById('close_modal_btn').click();
              }
            });
        },
     redirectToError(response){
       if(!response.proceed_cancellation){
          this.$store.dispatch('setRefundErrors', response).then(
            ()=>{
                  this.$router.push({'name': 'error_page'})
            }
          )
        }
     }
  },
   created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
        // this.$store.dispatch('cleanRefundFields');
        this.$store.dispatch('ValidateBookingCancellation',
                             {cancel_hash:this.$route.params.cancel_token,
                              user_token: this.$route.params.prop_id}).then((response)=>{this.redirectToError(response)})
    }
}
</script>

<style scoped>
  #cancel_container{
    min-height:80vh;
  }
</style>
