/*
this is going to be the container to gather the details about the event
to be booked.
*/

<template>
    <!-- <div class="carousel-item active" v-for="(image, index) in event_selected.images" :key='index' data-bs-interval="10000">
                            <img :src="image.url" class="d-block w-100" alt="...">
    </div> -->

    <div class="mb-3">
        <!-- <div id="carouselExampleInterval" class="carousel slide card-image-top" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" v-for="(image, index) in event_selected.images" :key='index' data-bs-interval="10000">
                        <img :src="image.url" class="d-block w-100" alt="...">
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
        </div> -->
      <img :src="event_selected.bookingEventConfiguration.event_content_image" class="card-img-top image_renderer"
         alt="event_image" v-if='event_selected.bookingEventConfiguration.event_content_image'>
      <img :src="event_selected.images[0].url" class="card-img-top image_renderer"
         alt="event_image" v-else-if='event_selected.images'>
        <img src="../assets/default.png" width="400px" class="card-img-top image_renderer" alt="event_image" v-else>
            <div class="card-body">
                <div class="title_container">
                    <h2 class="card-title mt-4">
                        {{event_selected.name}}
                    </h2>
                </div>
                <hr>
                <div class="description_container">
                    <p class="card-text" style="color:gray;" v-html="event_selected.description"></p>
                </div>

            </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
export default {
    name:'EventDetail',
    computed:mapGetters(['event_selected']),
    // components:{
    //     Carousel,
    //     Slide,
    //     Pagination,
    //     Navigation,
    // },


}
</script>

<style scoped>
.detail_container{
    display: flex;
    background-color:#fafafa;
}
.event_title{
    color:green;
}
.title_container{
    text-align: left;
}
.image_renderer{
    max-height: 390px;
    border-radius: 6px;
}
.description_container{
    text-align: left;
}
.carousel-inner{
    height: 450px;
}

</style>







