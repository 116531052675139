// this is the footer for the page
<template>
  <div class="footer_container">
      <p> &copy Grupo Puntacana, Dominican Republic</p>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer_container{
        margin-left:3%;
        margin-top: 3%;
        color:#c1c1c1;
        text-align: left;
    }
</style>
