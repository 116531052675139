// this is the template to take care of the
// of the existing event based on the data.

<template>
  <div class="row" v-cloak>
    <div class="col-6" style="text-align: left;">
      <!-- date and number of sits available -->
      <h5>
        {{availability.formated_start_time}}
      </h5>
      <p v-if="index_available[availability.startTime] && event_selected.bookingEventConfiguration.show_available_seats">
        Available {{index_available[availability.startTime].numSeatsAvailable}}
      </p>
    </div>
    <div class="col-6" style="text-align: right">
      <!-- here we need to determine whether this is a free booking or not. -->
      <Button title='BOOK NOW' class="booking_btn_component" render_class="btn btn-success booking_btn " @click="proceedBooking"/>
    </div>
  </div>
</template>

<script>
import Button from './Button.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  name:'AvailableEvent',
  props:{
    availability:{
      type: Object
    }
  },
  computed:{
    ...mapGetters(['index_available', 'prop_token', 'event_selected', 'isCollaborator']),
  },
  components:{
    Button
  },
  methods:{
    ...mapActions(['createBookingHold']),
    proceedBooking(){
      this.$store.dispatch('createBookingHold', this.availability).then(
        (response)=>{
          if(response.status){
            // here we redirect
            this.$toast.add({severity:'success',
              summary:'Booking Selected',
              detail: response.message,
              life:7000})
            this.$store.dispatch('holdTimerConfig', true).then(() => {
              this.$router.push({name:'booking_form',
                params: {event_id: this.event_selected.productId,
                  prop_id: this.prop_token}})
            });

          }

          else{
            // here we render error.
            this.$toast.add({severity:'error',
              summary:'Error',
              detail: response.message,
              life:7000})
          }
        }
      ).catch(
        (error_response)=>{
          this.$toast.add({severity:'error',
            summary:'Error',
            detail: error_response,
            life:7000})
        }
      )

    },
  }
}
</script>

<style scoped>
[v-cloak]{
  display:none;
}
.booking_btn{
  margin-top:1px;
}
/* Responsive font size adjustments */
@media (max-width: 1199px) {
  h5 {
    font-size: 1.4rem; /* Reduce font size for smaller screens */
  }
  .booking_btn_component {
    font-size: 0.8rem;
  }
}

</style>
