<template>
  <div class="container generic_floating_container free_booking_container">
      <PaymentConfirmationHeader 
             header_title="Booking Confirmation"/>
            <PaymentConfirmationBody
             :confirmation="booking_confirmation.confirmation"/>
      <PaymentConfirmationFooter 
       :email_to="booking_confirmation.reservation_email"
       :redirect_url="booking_confirmation.redirect_url" />

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PaymentConfirmationFooter from '../components/PaymentConfirmationFooter.vue'
import PaymentConfirmationHeader from '../components/PaymentConfirmationHeader.vue'
import PaymentConfirmationBody from '../components/PaymentConfirmationBody.vue'

export default {
    name:'FreeBookingSuccessReciept',
    components:{
        PaymentConfirmationFooter,
        PaymentConfirmationHeader,
        PaymentConfirmationBody
    },
    computed:{
        ...mapGetters(['booking_confirmation'])
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader'])
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
    }

}
</script>

<style scoped>
.free_booking_container{
    margin-top:15%;
}
@media screen and (max-width:700px) {
    .free_booking_container{
        margin-top:35%;
        max-width: 90%;
    }
}
</style>