<template>
    <button :class="render_class" >
        {{ title }}
        <i :class="icon_class" v-if="icon_class"></i>
    </button>
</template>
<script>
export default {
  name: 'GenericBtn',
  props: {
    title: {
      type: String,
      default: 'Book now'
    },
    render_class: {
      type: String,
      default: 'btn-primary'
    },
    icon_class:{
      type:String,
      default:''
    }
  }
}
</script>
<style scoped>
button{
  color: white;
  text-align: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 10px 10px 5px 10px;

}
</style>
