// this is just the header based on the existing data

<template>
<div class="title_container">
    <hr>
    <div class="row">
        <div class="col-md-6">
            <h3 class="event_name_title">
                {{event_name}}
            </h3>
            <div class="row">
                <div class="col-md-4">
                <h5> {{selected_date}}</h5>
                </div>
                <div class="col-md-4">
                    <h5> <b>{{people_number}} Pax  USD{{ amount }} </b></h5>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <GenericBtn title="Cancel"  render_class="btn btn-danger" data-bs-toggle='modal' data-bs-target='#modal_container' />
            <GenericBtn v-if="!terms_accepted && parseInt(amount.replace('$',''))>0" title="Complete Payment"  render_class="btn btn-success" disabled />
            <GenericBtn v-if="terms_accepted && parseInt(amount.replace('$',''))>0.00" title="Complete Payment"  render_class="btn btn-success" @click='processBooking'/>
            <GenericBtn v-if="!terms_accepted && parseInt(amount.replace('$',''))<1" title="Complete Booking"  render_class="btn btn-success" disabled />
            <GenericBtn v-if="terms_accepted && parseInt(amount.replace('$',''))<1" title="Complete Booking"  render_class="btn btn-success" @click='processBooking'/>
        </div>
    </div>

</div>
</template>

<script>
import GenericBtn from './Button.vue'
import {mapActions, mapGetters} from 'vuex'
export default {
    name: "BookingFormFooter",
    components:{
        GenericBtn
    },
    computed:mapGetters(['terms_accepted', 'property_fetcher']),
    props:{
        event_name:{
            type: String
        },
        selected_date:{
            type: String
        },
        people_number:{
            type: Number
        },
        amount:{
            type: String
        },
    },
    methods:{
        processBooking(){
            this.$emit('storeBooking')
        }
    },
    emits:['storeBooking']

}
</script>

<style scoped>
.title_container{
    text-align: left;
    padding: 5px 5px 25px 10px;
    max-width: 87%;
}
.event_name_title{
    color:green;
}
@media screen and (max-width:900px) {
    .title_container{
    max-width: 100%;
}
}
</style>
