<template>
    <div class="main_container_hotel" 
     v-if="property_fetcher.configuration.validate_property_guest">
        <div class="form_container">
            <h3 class="form_title">
                Please provide your hotel information
            </h3>
            <div class='row' >
            <div class="col-md-3">
                <div class='input_container form-group'>
                    <p class="label_formater"> Select an option </p>
                    <vSelect :options="types"
                    v-model="reservationType" class='select_renderer'
                    :clearable='false'/>
                </div>
            </div>
            <div class="col-md-3">
                <div v-if="reservationType.code === 'room_number'">
                    <Input html_unique="room_number" 
                        input_type="number" label_title="Room Number" 
                        v-model="roomNumber" 
                        :validation="ruleset_validation.HotelGuestRoom.room_number"
                        :required_f="false"/>
                </div>
                <div v-else-if="reservationType.code === 'reservation_number'">
                    <Input html_unique="reservation_number" 
                        input_type="text" label_title="Confirmation Number" 
                        v-model="roomNumber" 
                        :validation="ruleset_validation.HotelGuestRoom.room_number"
                        :required_f="false"/>
                </div>
               
            </div>
            <div class="col-md-4" v-if="reservationType.code !== undefined">
               <Input html_unique="room_last_name" 
                 input_type="text" label_title="Lastname" 
                 v-model="lastName"
                 :validation="ruleset_validation.HotelGuestRoom.last_name"
                 :required_f="false"/>
            </div>
           
        </div>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Input from './Input.vue'
import vSelect from 'vue-select';

export default {
    name:"HotelGuestCheck",
    props:{},
    data(){
        return{
            types: [{code: 'room_number', label: 'Room number'}, {code: 'reservation_number', label: 'Confirmation number'}],
        }
    },
    components:{
        Input,
        vSelect
    },
    computed:{
        ...mapGetters(['guest_hotel',
                       'property_fetcher',
                       'ruleset_validation']),
        reservationType:{
            get(){
                return this.guest_hotel.request_type;
            },
            set(value){
                var sender = {key:'request_type', val: value}
                this.$store.dispatch('fillHotelGuestInfo', sender)
            } 
        },
        roomNumber:{
            get(){
                return this.guest_hotel.room_number;
            },
            set(value){
                var sender = {key:'room_number',
                              val: value}
                this.$store.dispatch('fillHotelGuestInfo', sender);

            }
        },
        lastName:{
            get(){
                return this.guest_hotel.last_name;
            },
            set(value){
                var sender = {key:'last_name',
                              val: value}
                this.$store.dispatch('fillHotelGuestInfo', sender);
            }
        },
    },
    methods:{
        ...mapActions(['fillHotelGuestInfo'])
    }

}
</script>

<style scoped>
.main_container_hotel{
    padding-bottom: 30px;
}


.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.select_renderer{
    cursor: pointer;
}


.error_displayer{
    max-height: 20%;
}
.error_message{
    margin: 0px 10px 1px 10px;
	padding: 0px 0px 1px 0px;
    color: red;
}
.error_displayer{
	height: 39px;
	text-align: left;
	margin: 10px 10px 10px -26px;
	padding: 10px 10px 10px 0px;
}
.input_container{
    margin: 0px 0px 25px 10px;
}

.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.error-boarder {
  border-color: red;
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #f7f7f7;
	background-clip: none;
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
    border-bottom: none;
	border-radius: 0px;
    background-color: #f7f7f7;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom_input {
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0px;
    background-color: #f7f7f7;
    height: 26px;
	/* border: solid black; */
}
</style>