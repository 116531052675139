<template>
    <div class="container generic_floating_container align_text_left p-4">
        <div class="modal-title">
            <BookingFormHeader  :event_name=event_selected.name
             :selected_date="new Date(date_selected).toDateString()" />
             <h4>Please complete the participant info in order to complete your booking.</h4>
        </div>
    </div>
    <div class="container generic_floating_container align_text_left p-4">
        <div class="participat_container">
            <h3 class="form_title">
                Participants
            </h3>
            <small>Please complete the participants info</small>
            <div class="participants">
                <div class="guest_form" v-for="guest in guests" :key='guest.position'>
                    <GuestForm :guest_info="guest" :readonly_input="false"/>
                </div>

            </div>
            <GenericBtn class="genericBtn"  title="Complete Booking"  render_class="btn btn-success" @click='completeBooking'/>

        </div>

    </div>
                   
</template>
<script>

import {mapActions, mapGetters, mapState} from 'vuex';
import PaymentConfirmationFooter from '../components/PaymentConfirmationFooter.vue'
import PaymentConfirmationHeader from '../components/PaymentConfirmationHeader.vue'
import PaymentConfirmationBody from '../components/PaymentConfirmationBody.vue'
import GuestForm from '../components/GuestForm.vue'
import BookingFormHeader from '../components/BookingFormHeader.vue'
import HotelGuestCheck from '../components/HotelGuestCheck.vue'
import BookerInformationForm from '../components/BookerInformationForm.vue'
import GenericBtn from '../components/Button.vue'

export default {
    name:'PaymentSuccessReciept',
    components:{
        PaymentConfirmationFooter,
        PaymentConfirmationHeader,
        PaymentConfirmationBody,
        GuestForm,
        BookingFormHeader,
        HotelGuestCheck,
        BookerInformationForm,
        GenericBtn,
    },
    computed:{
        ...mapGetters(['booking_confirmation','booker','guests','selected_spot', 'event_selected', 'date_selected', 'booking', 'booking_confirmation']),
    },
    beforeRouteLeave(to, from, next) {
        if(to.name === 'payment_confirmation'){
            next(true);
        }
        next(false);
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader']),
        async completeBooking(){
            const full_validated = await this.$store.dispatch('runValidations',
                                                             'BookingForm');
             var send_request = false;

             if(full_validated.passed){
                this.$store.dispatch('updateBooking').then((response) => {
                    if(response.status){
                            this.$toast.add({severity:'success',
                                            summary:'Booking successfully created!!',
                                            detail: response.message,
                                            life:7000})

                        // here we redirect to the confirmation
                        this.$router.push({name:'payment_confirmation',
                                prop_id:this.prop_token,
                                event_id:this.event_selected.productId})
                    }
                    else{
                            // here we use toastr for displaying the error
                            this.$toast.add({severity:'error',
                                            summary:'Error Message',
                                            detail: response.message,
                                            life:7000})
                    }
                });
             } else {
               // here we use toastr for displaying the error
                this.$toast.add({severity:'error',
                                summary:'Error Message',
                                detail: full_validated.error,
                                life:7000})

           }

        }
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
    }

}

</script>
<style scoped>
.align_text_left {
    text-align: left;
}
.participat_container {
    position: relative;
}

.genericBtn {
    position: absolute;
    bottom: -16px;
    right: 0;
}
</style>
