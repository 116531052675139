/*
this is going to be the container to gather the components in order to make sure
the selection of the booking is right.
this contains:
- NumberPeople
- Calendar
- List available bookings.
*/
<template>
  <div class="container" v-if="selected_event.bookingLimits !== undefined">
    <div v-for="( value, index) in selected_event.defaultRates">
      <NumberPeople
        :maxPeopleCount="selected_event.bookingLimits[index].max"
        :minPeopleCount="selected_event.bookingLimits[index].min"
        :unitPrice="value.price"
        :peopleCategory="value.peopleCategoryId"
        :title="title"
        class="mb-4"/>
    </div>
    <div class="info_people alert alert-info" v-if="event_selected.bookingEventConfiguration.is_recurring_event" >
      <p>
        <i class="fa-solid fa-circle-info"></i>
        if you have kids below 2 years, there's no need to
        add them as a participant.
      </p>
    </div>
    <div class="info_people alert alert-info" v-if="isCollaborator">
      <p>
        <i class="fa-solid fa-circle-info"></i>
        As a collaborator, you enter for free and have 2 monthly slots at
        a preferential price (US ${{ event_configuration.event_configuration.guest_configuration.Price }}.00).
        After using these slots, the regular price of (US ${{selected_event.defaultRates[0].price.amount}}.00 ) will apply.
      </p>
    </div>

    <div
      class="m-2"
      v-if="event_configuration.event_configuration !== undefined &&
       event_configuration.event_configuration.property_configuration.slots">
      <h4 style="text-align: left;">Slots available</h4>
      <div class="card p-6 slots_card_container">
        <div class="card-body">
          <p>{{event_configuration.event_configuration.collaborator_slot.available_slots}}</p>
        </div>
      </div>
    </div>
  <div class="m-3" v-if="isCollaborator">
    <h4 style="text-align: left;">Are you going to be assisting?</h4>
    <div style="text-align: left; margin-left: -1rem">
      <label for="one" class="radio_options">
        <input type="radio" id="one" :value="true" v-model="participatingValue" />
        Yes
      </label>
      <label for="two" class="radio_options">
        <input type="radio" id="two" :value="false" v-model="participatingValue" />
        No
      </label>
    </div>
  </div>
  <div>
    <EventCalendar />
  </div>
    <div class="event_choice_options m-4"  v-if="event_selected.bookingEventConfiguration.available_options">
      <h4 style="text-align: left" >Choose available option</h4>
      <div class="form-group">
        <select
          id="customSelect"
          v-model="choiceEventOption"
          class="form-control custom-select wrapped-select"
        >
          <option
            v-for="option in mappedOptions"
            :key="option.value"
            :value="option"
            class="wrapped-option"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <div style="text-align: left" class="mt-2" v-if="choiceEventOption.description">
        <strong>Description</strong>
        <small v-html="choiceEventOption.description"/>
      </div>
    </div>

  <div class="available_spots">
    <AvailableEventList />
  </div>
  </div>

</template>
<script>
import NumberPeople from './NumberPeople.vue';
import EventCalendar from './Calendar.vue';
import AvailableEventList from './AvailableEventList.vue';
import { mapGetters, mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';
import Button from '../components/Button.vue'

export default {
  name:'SideBookingBar',
  props:{
    selected_event: {
      type:Object
    }
  },
  // computed:
  components:{
    NumberPeople,
    EventCalendar,
    AvailableEventList,
    vSelect,
    Button
  },
  data(){
    return{
      title: "Specify number of participants",
      choiceEventOption: null
    }
  },
  methods:{
    ...mapActions(['searchAvailiability', 'handleEventChoiceOption']),
    goBack(){
      var property_id = this.$route.params.prop_id;
      this.$store.dispatch('resetBookingInfo');
      this.$toast.add({severity:'success',
        summary:'Booking cancelled',
        detail: `your booking has been successfully cancelled`,
        life:7000})
      this.$router.push({name:'property_event_list', params:{prop_id:property_id}})
    },
  },
  computed:{
    ...mapGetters(['event_configuration', 'event_selected', 'collaborator_included', 'isCollaborator', 'booking', 'eventChoiceOption']),
    mappedOptions() {
      if (this.event_selected.choiceOptions) {
        return this.event_selected.choiceOptions[0].values.map(option => ({
          value: option.name,
          label: option.name,
          description: option.description
        }))
      }
    },
    participatingValue: {
      get() {
        return this.collaborator_included;
      },
      set(value) {
        this.$store.dispatch('updateCollaboratorParticipation', value);
        this.$store.dispatch('searchAvailiability');
      }
    },
  },
  watch: {
    // Watch for changes to choiceEventOption
    choiceEventOption(newVal, oldVal) {
      console.log('choiceEventOption changed from', oldVal, 'to', newVal);
      this.$store.dispatch('handleEventChoiceOption', newVal);
      this.$store.dispatch('searchAvailiability');
    },
    mappedOptions: {
      immediate: true,
      handler(newOptions) {
        if (newOptions?.length > 0) {
          this.choiceEventOption = newOptions[0]; // Select the first option by default
        }
      },
    },
  },
  mounted() {
    if (!this.event_selected.bookingEventConfiguration.is_recurring_event) {
      this.booking.start_time = this.event_selected.bookingEventConfiguration.start_time;
      this.booking.end_time = this.event_selected.bookingEventConfiguration.end_time;
      this.$store.dispatch('searchAvailiability');
    }

    if (this.mappedOptions?.length > 0) {
      this.choiceEventOption = this.mappedOptions[0]; // Ensure the first option is selected on mount
    }
  },
}
</script>
<style scoped>
.info_people{
  //margin: 5px 20px 5px 50px;
  padding: 10px 10px 10px 10px;
  margin-top: 12px;
  text-align: left;
}

.people_selector{
  padding: 10px 10px 10px 10px;
}
.available_spots{
  overflow-y: scroll;
  padding: 1rem;
}
@media only screen and (max-width: 700px) {
  .main_component_holder{
    margin-left: -10%;
  }
}
.radio_options{
  margin: 1rem;
  padding-left: 1.5rem;
  font-size: 20px;
}

.slots_card_container{
  max-width: fit-content;
  max-height: 50px;
  text-align: center;
  margin-left: 1rem;
}

/* Enable text wrap within the displayed selected option */
.wrapped-select {
  white-space: normal; /* Allow wrapping in the display */
  word-wrap: break-word;
  overflow-wrap: anywhere;
  height: auto; /* Adjusts to content height */
  max-width: 100%;
}

.wrapped-option {
  white-space: normal; /* Allow wrapping in dropdown options */
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
</style>
