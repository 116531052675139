// this component holds the main  booker
<template>
  <div class="user_container">
    <h3 class="form_title" v-if="!detailMode">
      Please complete your information
    </h3>
    <div class="form_container">
      <div class="row">
        <div class="col-md-6 primary_col">
          <Input input_type="text" label_title="Name"
                 v-model="firstName"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.first_name"
                 html_unique="booker_first_name"
                 :required_f='false'
                 disabled
          />
          <Input input_type="text" label_title="Lastname" v-model="lastName"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.last_name"
                 html_unique="booker_last_name"
                 :required_f='false'
          />
            <Input input_type="text" label_title='Home Address (Property address at Puntacana)' v-model="homeAddress"
                   :validation="ruleset_validation.HomeAddressForm.homeAddress"
                   html_unique="home_address"
                   :required_f='false'
                   v-if="event_selected.textOptions?.find(option => option.name.includes(textInputOption.homeAddress.identifier))"
            />
          <LanguageSelect v-if="!detailMode" label_title="Please select your preferred language"/>
        </div>

        <div class="col-md-6">
          <Input input_type="email" label_title="Email" v-model="email"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.email"
                 html_unique="booker_email"
                 :required_f='false' />

          <Input input_type="tel" label_title="Phone Number"
                 v-model="phoneNumber"
                 :disable_input="detailMode"
                 :validation="ruleset_validation.BookingForm.booking_creator.phone_number"
                 html_unique="booker_phone_number"
                 :required_f='false'
                 masked=""
          />
          <div class="form-group">
            <div class="form-control" style="text-align: left;">
              <p style="font-size: 13px">Special Request</p>
              <textarea
                class="outline_policy"
                v-model="specialRequest"
                id="specialRequest"
                name="specialRequest"
                rows="4"
                cols="175"
                :placeholder="event_selected.bookingEventConfiguration.special_request_placeholder"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Input from './Input.vue'
import { required, email, minLength } from '@vuelidate/validators'
import LanguageSelect from './LanguageSelectComponent.vue'

export default {
  name: "BookerInformationForm",
  props:{
    detailMode: {
      type: Boolean,
      default: false,
    },
  },
  validators(){
    return{
      firstName:{required},
      lastName:{required},
      email: {required, email},
      phoneNumber:{required,
        min: minLength(11)},
      birthDate:{required}
    }
  },
  computed:{
    ...mapGetters(['booker','ruleset_validation', 'isCollaborator', 'collaborator', 'textInputOption', 'event_selected', 'textInputOption']),

    firstName:{
      get(){
        return this.booker.first_name;
      },
      set(value){
        const sender = {key:'first_name', val: value}
        this.$store.dispatch('createFakeBookingGuest', value)
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    homeAddress: {
      get() {
        return this.booker.homeAddress;
      },
      set(value){
        const sender = {key:'home_address', val: value}
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    lastName:{
      get(){
        return this.booker.last_name;
      },
      set(value){
        const sender = {key:'last_name', val: value}
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    email:{
      get(){
        return this.booker.email;
      },
      set(value){
        const sender = {key:'email', val: value}
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    phoneNumber:{
      get(){
        return this.booker.phone_number;
      },
      set(value){
        const sender = {key:'phone_number', val: value}
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    birthDate:{
      get(){
        return this.booker.birthdate;
      },
      set(value){
        const sender = {key:'birthdate', val: value}
        this.$store.dispatch('fillBookerInfo', sender);
      }
    },
    specialRequest: {
      get(){
        return this.booker.special_request;
      },
      set(value) {
        const sender = {key: 'special_request', val: value}
        this.$store.dispatch('fillBookerInfo', sender)
      }
    }
  },
  methods:{
    ...mapActions(['fillBookerInfo', 'createFakeBookingGuest', 'configureTextOptionInputs', 'configureRequiredTextOption']),
  },
  components:{
    Input,
    LanguageSelect
  },
  mounted() {
    if(this.isCollaborator){
      this.firstName = this.collaborator.name;
      this.email = this.collaborator.email;
      this.booker.username = this.collaborator.email;
    }
    this.$store.dispatch('configureTextOptionInputs');

  },
}
</script>

<style scoped>
.outline_policy{
  border: #c1c1c1 1px solid;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  max-width:100%;
  max-height: 120px;
  overflow-y: scroll;
  font-size: 10px;
  resize: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #f7f7f7;
  background-clip: none;
  border: 1px solid #c6c6c6;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-radius: 0px;
  background-color: #f7f7f7;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
</style>
