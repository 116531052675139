<template>
  <div class="container confirmation_container">
      <table class="table table-stripped">
        <tr v-if="confirmation.last_4_card_digits">
            <td class="title_confirmation">
                Credit Card
            </td>
            <td class="confirmation">
                {{confirmation.last_4_card_digits}}
            </td>
        </tr>
        <tr v-if="confirmation.payment_confirmation_transaction_id">
            <td class="title_confirmation">
                Authorization Code
            </td>
            <td class="confirmation">
                {{confirmation.payment_confirmation_transaction_id}}
            </td>
        </tr>
         <tr>
            <td class="title_confirmation">
                Date
            </td>
            <td class="confirmation">
                {{confirmation.scheduled_for}}
            </td>
        </tr>
         <tr>
            <td class="title_confirmation">
                Booking Number
            </td>
            <td class="confirmation">
                {{confirmation.bookeo_confirmation_id}}
            </td>
        </tr>
         <tr v-if="confirmation.payment_attempt_id">
            <td class="title_confirmation">
                Transaction ID
            </td>
            <td class="confirmation">
                {{confirmation.payment_attempt_id}}
            </td>
        </tr>
      </table>
  </div>
</template>

<script>
export default {
    name:'PaymentConfirmationBody',
    props:{
        confirmation: {
            type:Object
        }
    },
}
</script>

<style scoped>
.confirmation_container{
    text-align: left;
}
.title_confirmation{
    text-align: left;
}
.confirmation{
    text-align: left;
}
@media screen and (max-width:700px) {
   .confirmation_container{
    margin-left: 0%;
} 
}
</style>