<template>
  <ErrorPage :error_code="404"
    error_message="The page you're looking for doesn't exist." />
</template>

<script>
import ErrorPage from './ErrorPage.vue'
export default {
name:'NotFound',
components:{
    ErrorPage
}
}
</script>

<style>

</style>