<template>
  <div class="container generic_floating_container">
      <PaymentConfirmationHeader
       header_title="Booking Refund Confirmation"/>
            <RefundConfirmationBody
             :confirmation="refund_success"/>
      <PaymentConfirmationFooter
       :email_to="refund_success.email"
       :redirect_url="redirectUrl" />

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PaymentConfirmationFooter from '../components/PaymentConfirmationFooter.vue'
import PaymentConfirmationHeader from '../components/PaymentConfirmationHeader.vue'
import RefundConfirmationBody from '../components/RefundConfirmationBody.vue'

export default {
    name:'BookingRefundSuccess',
    components:{
        PaymentConfirmationFooter,
        PaymentConfirmationHeader,
        RefundConfirmationBody
    },
    computed:{
        ...mapGetters(['redirectUrl','refund_success'])
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader'])
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
    }

}
</script>

<style>

</style>
