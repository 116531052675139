<template>
  <div class="input_container form-group">
      <label for="" class="form-control">
          <p class="label_formater"
           :id="html_unique+'_label'"> {{label_title}} </p>
<!--          <InputMask :type="input_type"-->
<!--           v-model='inputValue'-->
<!--           class="form-control custom_input"-->
<!--           :id="html_unique"-->
<!--           :mask="masked"-->
<!--           :unmask="true"-->
<!--           autocomplete="off"-->
<!--           autocorrect="off"-->
<!--           v-if="masked"/>-->
          <input
            :type="input_type"
            v-model="inputValue"
            class="form-control custom_input"
            :id="html_unique"
            v-mask="masked"
            v-if="masked"
          />

          <input :type="input_type"
           v-model='inputValue'
           class="form-control custom_input"
           :id="html_unique"
           autocomplete="off"
           autocorrect="off"
           :disabled="disable_input"
                 :maxlength="maxlength || null"
           v-else >

           <div class="error_displayer" v-show="validation.showErrors">
               <ul>
                 <li class="error_message" v-for="(validator, index) of validation.validators" :key='index'>
                    <small>
                        {{validator.error}}
                    </small>
                </li>
               </ul>

           </div>

      </label>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import InputMask from 'primevue/inputmask';
import { useToast } from "primevue/usetoast";
import vSelect from 'vue-select';

const cardValidator = require("global-card-ident");

export default {
    name: "Input",
    props: {
        label_title:{
            type: String
        },
        input_type:{
            type: String
        },
        modelValue:{
            type: String
        },
        validation:{
            type: Object,
        },
        html_unique:{
            type: String
        },
        masked:{
            type: String,
            default: ""
        },
        required_f:{
            type: Boolean,
            default: false
        },
        is_bank_card:{
          type: Boolean,
          default: false
        },
        disable_input: {
            type: Boolean,
            default: false,
        },
        maxlength: {  // Optional maxlength prop
          type: [Number, String],
          default: null
        },
    },
    components:{
        InputMask,
        vSelect
    },
    data(){
      return {
        format_validated: false,
        take_mask: this.masked,
        is_american_express: false,
        american_express_mask: '9999-999999-99999',
        cardOptions: [
          'Visa',
          'Master Card',
          'American Express',
          'Other'
        ],
        selectedCard: 'Visa'
      }
    },
    emits:['update:modelValue'],
    computed:{
        inputValue:{
            get(){
                var result = this.modelValue;
                if(this.required_f){
                    let ob = {
                        value: result,
                        validation: this.validation
                    }
                    // this validation must be fixed
                    this.$store.dispatch('ValidateField', ob).then(
                        (result)=>{
                            let action = (!result)?'display':'remove';
                            this.highlightError(action);
                        }
                    ).catch(
                        (error)=>{
                             this.$toast.add({severity:'error',
                                         summary:'Error Message',
                                         detail: error,
                                         life:7000})
                        }
                    );
                }
                if(this.validation.showErrors){
                    let action = 'display';
                    // this.highlightError(action);
                }
                return result;
            },
            set(value){
                let ob = {
                    value:value,
                    validation: this.validation
                }

                // this validation must be fixed
                this.$store.dispatch('ValidateField', ob).then(
                    (result)=>{
                            let action = (!result)?'display':'remove';
                            this.highlightError(action);
                            this.$emit('update:modelValue', value)
                    }
                ).catch(
                    (error)=>{
                        this.$toast.add({severity:'error',
                                         summary:'Error Message',
                                         detail: error,
                                         life:7000})

                    }
                );
            }
        }
    },
    methods:{
        ...mapActions(['ValidateField']),
        validateCardType(value){
          /*
            validates the type of
            card and generates a field
            with the given mask that it requires
            :return: null
          */
          const cardBrand = cardValidator(value);
          if (cardBrand){
            if (cardBrand.brand === 'amex'){
              this.take_mask = this.american_express_mask;
              this.is_american_express = true;
              this.format_validated = true;
            }

          }
          else{
            this.take_mask = this.masked;
            this.is_american_express = false;
            this.format_validated = true;
            this.use_generic_masked = true;
          }
        },
        highlightError(action){
            /*
            highlights the field to show the error
            :returns: null
            */
           if (this.html_unique){
               let input_field = document.getElementById(this.html_unique)
               let label = document.getElementById(this.html_unique+'_label')
               if (action == 'display'){
                    input_field.style['border-color'] = 'red'
                    label.style['color']='red'
               }
               else{
                    input_field.style['border-color'] = '#c6c6c6'
                    label.style['color']='black'
                }
            }
        }
    }
}
</script>

<style scoped>
.error_displayer{
    max-height: 20%;
}
.error_message{
    margin: 0px 10px 1px 10px;
	padding: 0px 0px 1px 0px;
    color: red;
}
.error_displayer{
	height: 39px;
	text-align: left;
	margin: 10px 10px 20px -26px;
	padding: 10px 10px 10px 0px;
}
.input_container{
    margin: 0px 0px 5px 0px;
}

.label_formater {
	margin: 0px 0px 2px 0px;
    font-size: 13px;
    text-align: left;

}
.error-boarder {
  border-color: red;
}

.form-control {
	display: block;
	width: 100%;
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	background-color: #f7f7f7;
	background-clip: none;
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
    border-bottom: none;
	border-radius: 0px;
    background-color: #f7f7f7;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom_input {
    border: 1px solid #c6c6c6;
	border-top: none;
	border-left: none;
	border-right: none;
	border-radius: 0px;
    background-color: #f7f7f7;
    height: 26px;
	/* border: solid black; */
}
</style>
