import {createRouter, createWebHistory} from 'vue-router'
import EventList from '../views/EventList'
import ErrorPage from '../views/ErrorPage'
import EventBooking from '../views/EventBooking'
import BookingForm from '../views/BookingForm'
import PaymentForm from '../views/PaymentForm'
import CompleteBooking from '../views/CompleteBooking.vue'
import NotFound from '../views/NotFound'
import PaymentSuccessReciept from '../views/PaymentSuccessReciept'
import FreeBookingSuccessReciept from '../views/FreeBookingSuccessReciept'
import cancelBookingView from '../views/CancelBookingView'
import BookingRefundSuccess from '../views/BookingRefundSuccessful'
const api = ()=>{return {api_url: process.env.VUE_APP_BACKEND_API_URL}}
const api_url = api().api_url
const routes = [
    {
        path:'/property/:prop_id/events/',
        name: 'property_event_list',
        component: EventList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:'/property/:prop_id/collaborator/:employee_id/hash/:hash_id/events',
        name: 'property_event_collaborator_list',
        component: EventList,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/error_page/',
        name: 'error_page',
        component: ErrorPage,
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/property/:prop_id/events/:event_id/booking/',
        name: 'booking_form',
        component: BookingForm,
        meta: {
            requiresAuth: true
        }
    }
    ,
    {
        path: '/property/:prop_id/events/:event_id/schedule/',
        name: 'event_schedule',
        component: EventBooking,
        meta: {
            requiresAuth: true
        }
    },
    {
        path:"/property/:prop_id/events/:event_id/process_payment/",
        name:'confirm_booking',
        component: PaymentForm,
        meta: {
            requiresAuth: true
        }

    },
    {
        path:"/property/:prop_id/complete_booking/",
        name:'booking_detail',
        component: CompleteBooking,

    },
    {
        path:"/property/:prop_id/payment_completed/thanks/",
        name:'payment_confirmation',
        component: PaymentSuccessReciept,
        meta: {
            requiresAuth: true
        }

    },
    {
        path:"/property/:prop_id/booking_completed/thanks/",
        name:'booking_completed',
        component: FreeBookingSuccessReciept,
        meta: {
            requiresAuth: true
        }
    },
    {
      path:"/cancel_booking/:cancel_token/auth/:prop_id/",
      name:'cancel_booking',
      component: cancelBookingView,
      meta: { requiresAuth: true }
    },
    {
      path:"/cancel_booking/:cancel_token/auth/:prop_id/success/",
      name:'success_refund',
      component: BookingRefundSuccess,
      meta: {
          requiresAuth: true
        }
    },
    {
        path: '/culinaryweekend',
        redirect: {
            name: 'property_event_list',
            params: { prop_id: process.env.VUE_APP_CULINARY_WEEKEND_PROP_ID }
        }
    },
    {
      path: '/newyearseve-2024-clubhouse',
      redirect: {
        name: 'property_event_list',
        params: { prop_id: process.env.VUE_APP_CHRISTMAS_CLUBHOUSE_PARTY_PROP_ID }
      }
    },
    // {
    //   path: '/newyearseve-2024-playablanca',
    //   redirect: {
    //     name: 'property_event_list',
    //     params: { prop_id: process.env.VUE_APP_CULINARY_WEEKEND_PROP_ID }
    //   }
    // },
    {
        path:"/:pathMatch(.*)*",
        name:'no_found',
        component: NotFound,
        meta: {
            requiresAuth: false
        }
    },
]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to,from, next)=>{
    if(to.name){
        NProgress.start();
    }

    // New redirect logic for the specific domain and path
    if (window.location.hostname === 'events.puntacana.com' && to.path === '/') {
      next({ path: '/culinaryweekend' });
      return;
    }

    if(to.meta.requiresAuth){
        // here we need to validate the route token
        let tok = to.params.prop_id
        // this.$store.state.property_token;
        const res = await fetch(`${api_url}/api/`,{
            method:'OPTIONS',
            headers:{'Authorization': `Token ${tok}`}
        })
        if(res.ok){
            next();
        }
        else{
            // this.$store.dispatch('resetStateToDefault');
            next({
                name:'error_page'
            });
        }
    }
    else{
        next();
    }
})

router.afterEach((to, from)=>{
    NProgress.done();
})
export default router
