
<template>
    <div v-show='loading' class="loader-overlay">
        <div class="spiner_container">
            <ProgressSpinner strokeWidth="1"/>
            <span class="text loader_text">
                <b>
                    {{text}}
                </b>
             </span>
        </div>

        <!-- <span class="text" v-html="text"></span> -->

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ProgressSpinner from 'primevue/progressspinner';
export default {
    name: "Loader",
    props: {
        text: {type: String,
               required: false,
               default: "Loading Booking"},
    },
    computed:{
        ...mapGetters(['loading'])
    },
    components:{
        ProgressSpinner,
    }
}
</script>

<style lang="scss" scoped>

    .spiner_container {
        margin: 25% 39% 0px 36%;
        // align-content: center;
        max-width: 30%;
        min-width: 30%;
        width:25%;
        max-height: 40%;
        height: 20%;
    }
    .p-progress-spinner {
        position: absolute;
        left: 52%;
        margin: 2% -5% auto;
        width: 100px;
        height: 100px;
        display: inline-block;
}
    .loader_text{
        margin: 10% 0px 0px 0px;
        // display: inline-block;
        // position: absolute;
        // top: 50%;
        // left: 47%;
        transform: translate(-50%,-50%);
        color: #fff;
        padding-top: 10%;
    }
    .loader-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
        cursor: pointer;
        .loader {
            animation: loader-animate 1.5s linear infinite;
            clip: rect(0, 80px, 80px, 40px);
            height: 80px;
            width: 80px;
            position: absolute;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            &:after {
                animation: loader-animate-after 1.5s ease-in-out infinite;
                clip: rect(0, 80px, 80px, 40px);
                content: '';
                border-radius: 50%;
                height: 80px;
                width: 80px;
                position: absolute;
            }
        }
        @keyframes loader-animate {
            0% {
                transform: rotate(0deg)
            }
            100% {
                transform: rotate(220deg)
            }
        }
        @keyframes loader-animate-after {
            0% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(-140deg);
            }
            50% {
                box-shadow: inset #fff 0 0 0 2px;
            }
            100% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(140deg);
            }
        }
    }
    @media screen and (max-width:700px) {
        .spiner_container {
        margin: 67% 39% 0px 36%;
        // align-content: center;
        max-width: 30%;
        min-width: 30%;
        width:25%;
        max-height: 40%;
        height: 20%;
    }
    .p-progress-spinner {
        position: absolute;
        left: 44%;
        margin: 4% -5% auto;
        width: 100px;
        height: 100px;
        display: inline-block;
}
    .loader_text{
        margin: 10% 0px 0px 0px;
        // display: inline-block;
        // position: absolute;
        // top: 50%;
        // left: 47%;
        transform: translate(-50%,-50%);
        color: #fff;
        padding-top: 10%;
    }
        .loader-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        z-index: 999;
        cursor: pointer;
        .loader {
            animation: loader-animate 1.5s linear infinite;
            clip: rect(0, 80px, 80px, 40px);
            height: 80px;
            width: 80px;
            position: absolute;
            left: calc(50% - 40px);
            top: calc(50% - 40px);
            &:after {
                animation: loader-animate-after 1.5s ease-in-out infinite;
                clip: rect(0, 80px, 80px, 40px);
                content: '';
                border-radius: 50%;
                height: 80px;
                width: 80px;
                position: absolute;
            }
        }
        @keyframes loader-animate {
            0% {
                transform: rotate(0deg)
            }
            100% {
                transform: rotate(220deg)
            }
        }
        @keyframes loader-animate-after {
            0% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(-140deg);
            }
            50% {
                box-shadow: inset #fff 0 0 0 2px;
            }
            100% {
                box-shadow: inset #fff 0 0 0 17px;
                transform: rotate(140deg);
            }
        }
    }
    }
</style>
