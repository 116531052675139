<template>
    <div class="container-fluid">
        <img src="../assets/ErrorLogo.png" alt=" error_image">
        <div v-if="show_cancellation_errors">
          <h1 class="error_msg">
              Error {{response_error_code}}
          </h1>
          <h5>
              {{response_error_message}}
          </h5>
        </div>
        <div v-else>
          <h1 class="error_msg">
              Error {{error_code}}
          </h1>
          <h5>
              {{error_message}}
          </h5>
        </div>

    </div>

</template>

<script>
import {mapActions, mapGetters} from 'vuex';
export default {
    name:'ErrorPage',
    computed: {
      ...mapGetters(['response_error_message',
                     'response_error_code',
                     'show_cancellation_errors'])
    },
    props:{
        error_message:{
            type: String,
            default: 'The Link provided is Invalid, Please contact the admin.'
        },
        error_code:{
            type: Number,
            default: 401
        }
    },
    methods:{
        ...mapActions(['changeDisplayMainHeader'])
    },
    created(){
        this.$store.dispatch('changeDisplayMainHeader', false)
    }
}
</script>

<style scoped>
img{
    margin-bottom:3%;
    max-width: 50%;
    max-height: 30%;
}
.error_msg{
    color:red;
}
</style>
