<template>
    <div>
        <div class="alert alert-warning" role="alert">
            Remaining time to complete your booking <span class="badge text-bg-secondary" id="base-timer-label">{{formatTime(timeLeft)}}</span>
        </div>
        <GenericBtn hidden title="Timer init" id="modal_btn"  render_class="btn btn-success" data-bs-toggle='modal' data-bs-target='#timer_modal' />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import GenericBtn from './Button.vue'

export default {
    name:"Timer",
    data(){
        return{
            FULL_DASH_ARRAY: 283,
            COLOR_CODES: {
                info: {
                    color: "green"
                },
                warning: {
                    color: "orange",
                    threshold: 30
                },
                alert: {
                    color: "red",
                    threshold: 15
                }
            },
            TIME_LIMIT: 0,
            timePassed: 0,
            timeLeft: 0,
            timerInterval: null,
            remainingPathColor: "green",
        }
    },
    components: {
        GenericBtn
    },
    watch: {
       booking_on_hold: {
        handler() {
            this.resetTimer();
        },
        immediate: true,
       },
    },
    mounted(){
        this.resetTimer();
    },
    computed:{
        ...mapGetters(['booking_on_hold', 'resetBookingInfo', 'holdTimer', 'removeBookingHold'])
    },
    methods: {
        resetTimer() {
            clearInterval(this.timerInterval); // Clear any existing timer
            let givenDate = new Date(this.booking_on_hold.expiration);
            const currentDate = new Date();
            if (currentDate.getTime() >= givenDate.getTime()) {
                this.onTimesUp();
            } else {
                this.TIME_LIMIT = Math.floor(Math.abs((currentDate - givenDate) / 1000));
                this.timeLeft = this.TIME_LIMIT;
                if (document.getElementById('modal_btn')?.classList.contains('active')) {
                    document.getElementById('modal_btn').click();
                    document.getElementById('modal_btn').classList.remove('active');
                }
                this.startTimer();
            }
        },
        stopTimer() {
            clearInterval(this.timerInterval);
        },
        onCalculateTimer() {
            let givenDate = new Date(this.booking_on_hold.expiration);
            const currentDate = new Date();
            if (currentDate.getTime() >= givenDate.getTime()) {
                this.onTimesUp();
            } else {
                this.TIME_LIMIT = 0;
                this.timeLeft = 0;
                this.TIME_LIMIT = Math.floor(Math.abs((currentDate - givenDate) / 1000));
                this.timeLeft = this.TIME_LIMIT;
                this.startTimer();
            }
        },
        onTimesUp() {
            clearInterval(this.timerInterval);
            if(this.holdTimer){
                if (document.getElementById('modal_btn')?.classList.contains('active')) {
                    document.getElementById('modal_btn').click();
                    document.getElementById('modal_btn').classList.remove('active');
                }
                this.$store.dispatch('removeBookingHold').then(() => {
                    this.$store.dispatch('resetBookingInfo').then(() => {
                    this.$router.push({name:'property_event_list',
                                        prop_id:this.prop_token})
                    })
                });
            }
        },
        formatTime(time) {
            const minutes = Math.floor(time / 60);
            let seconds = time % 60;

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            return `${minutes}:${seconds}`;
        },
        startTimer() {
            this.timerInterval = setInterval(() => {
                const now = Date.now();
                const timePassedSinceStart = now - (this.timePassed || now);
                this.timePassed = now;

                this.timeLeft = Math.max(Math.floor(this.timeLeft - timePassedSinceStart / 1000), 0);

                if(document.getElementById("base-timer-label")){
                    document.getElementById("base-timer-label").innerHTML = this.formatTime(
                        this.timeLeft
                    );

                    this.setCircleDasharray();
                    this.setRemainingPathColor(this.timeLeft);

                    if (this.timeLeft === 0) {
                        this.onTimesUp();
                    }
                }

            }, 1000);
        },
        setRemainingPathColor(timeLeft) {
            const { alert, warning, info } = this.COLOR_CODES;
            if (timeLeft <= alert.threshold) {
                if(timeLeft <= 2){
                    this.onTimesUp();
                }
            } else if (timeLeft <= warning.threshold) {

                if (!document.getElementById('modal_btn').classList.contains('active') && this.holdTimer) {
                    document.getElementById('modal_btn').click();
                    document.getElementById('modal_btn').classList.add('active');
                }
            }
        },
        calculateTimeFraction() {
            const rawTimeFraction = this.timeLeft / this.TIME_LIMIT;
            return rawTimeFraction - (1 / this.TIME_LIMIT) * (1 - rawTimeFraction);
        },
        setCircleDasharray() {
            const circleDasharray = `${(
                this.calculateTimeFraction() * this.FULL_DASH_ARRAY
            ).toFixed(0)} 283`;
        }
    }
}
</script>
<style>


.base-timer {
  position: relative;
  width: 75px;
  height: 75px;
  margin: auto;
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: grey;
}

.badge{
    font-size: 1rem;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  font-size: 1.2rem;
  stroke-linecap: round;
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  animation: blink 1s linear infinite;
  filter: drop-shadow(0 0 3px orange);

  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
  filter: drop-shadow(0 0 3px red);

  animation: blink 0.5s linear infinite;

}

.base-timer__label {
  position: absolute;
  width: 75px;
  height: 75px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}


@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
